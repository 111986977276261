.form-control:focus{


    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0 rgb(13 110 253 / 25%);
}
.form-control{
   
}