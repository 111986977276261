body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*General Classes*/
.boxShadaw{
    box-shadow: 0 2px 10px 0 #d8dfd82b;
}
.border-50{
    border-radius: 50%;
}

/*newsletter*/
.sc-htpNat.hmNbnh, .sc-bxivhb.hwzetk{
    display: none;
  }
.cOejr{
    margin: 0  0 50px !important;
    max-width: 70% !important;
}
.iOeItA, .cOejr{
    padding: 0 !important;
}
.iOeItA, .cOejr, .dKHcJV {
    background-color: transparent !important;
}
.dKHcJV{
    border-width: 1px !important;
    margin-right: 0 !important;
}
.hrfjOR{
    background-color: white !important;
    color: black !important;
    border-radius: 0 !important;
}

a{
    text-decoration: none !important;
}
.row{
    margin: 0 !important;
}
.productSlider .wishShow{
    grid-gap: 4% !important;
    gap: 4% !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

Table td img {
    width: auto;
    height: 6rem;
}
tbody, td, tfoot, th, thead, tr {
    vertical-align: middle;
}
.cartSum h5 {
    border-bottom: 1px solid #f4ecec;
}

.carousel-indicators {
    bottom: 3%;
  }
  .carousel-indicators [data-bs-target] {
    background-color: #dfd6d6;
    width: 15px !important;
    border-radius: 50%;
    height: 14px !important;
  }
  .carousel-indicators .active {
    background-color: #000 !important;
  }
 
.watchesTypes img {
  transition: 0.4s;
}
.watchesTypes img:hover {
  -webkit-filter: brightness(0.95);
          filter: brightness(0.95);
  box-shadow: 0 2px 10px 0 #ddddddb8;
}

.col-4 {
  width: 33.33333333%;
}
.productSlider .d-flex {
  grid-gap: 8px;
  gap: 8px;
}
.productSlider .price-old {
  color: #999;
  font-size: 16px;
  font-weight: 400;
  text-decoration: line-through;
}
.productSlider .price-new {
  font-size: 16px;
  font-weight: bolder;
}
.productSlider .card-title {
  font-weight: 300;
  text-align: left;
}
.productSlider .card-img-top {
  border: 1px solid rgba(0, 0, 0, 0.125);
  transition: 0.4s;
  height: 371px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.productSlider .card-img-top:hover {
  box-shadow: 0 2px 10px 0 #dbd8d863;
  -webkit-filter: brightness(0.98);
          filter: brightness(0.98);
}
.productSlider a {
  font-size: 15px;
  color: #404040;
  text-decoration: none;
}
.productSlider .btnGroup {
  opacity: 0;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  left: 25%;
  bottom: 25%;
}
.productSlider .card:hover .btnGroup,
.productSlider .card-img-top:hover .btnGroup{
  opacity: 1;
}
.productSlider .carousel-control-next-icon,
.productSlider .carousel-control-prev-icon{
  background-color: black;
}
.productSlider .carousel-control-prev {
  left: -4%;
}
.productSlider .carousel-control-next {
  right: -4%;
}
.productSlider .carousel-control-next,
.productSlider .carousel-control-prev{
  width: auto;
}
.productSlider .carousel-indicators{
  display: none;
}
.btn{
  border: 0;
  box-shadow: none !important;
}

.productTabs .nav-tabs .nav-link{
    color: rgb(143, 141, 141);
    font-weight: bold;
    font-size: 1.25rem;
}
.productTabs .nav-tabs .nav-item.show .nav-link,
.productTabs .nav-tabs .nav-link.active{
    color: black;
}
.productTabs .productSlider h5{
    display: none;
}
.BrandslogoSec{
    background: #f6f6f6;
}
.BrandslogoSec .react-multiple-carousel__arrow--left{
    left: calc(1% + 1px);
}
.BrandslogoSec .react-multiple-carousel__arrow--right {
    right: calc(0% + 1px);
}
.BrandslogoSec .react-multiple-carousel__arrow{
    background: none !important;
}
.BrandslogoSec .react-multiple-carousel__arrow::before{
    color: rgb(161, 157, 157);
}

.item-price {
	font-size: 1rem;
}
.item-price strike {
	opacity: 0.7;
    font-size: .8 rem;
}


.form-control:focus{


    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0 rgb(13 110 253 / 25%);
}
.form-control{
   
}
